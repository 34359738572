import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Welcome from "../components/welcome"
import MainLayout from "../layouts/main"

const Default: React.FC<PageProps> = () => (
  <MainLayout>
    <main id="main">
      <Welcome />
    </main>
  </MainLayout>
)

let IndexPage: React.FC<PageProps> = Default

switch (process.env.GATSBY_LANDING_PAGE) {
  case `splash`:
    IndexPage = React.lazy(() => import(`./splash`))
    break
  case `event`:
    IndexPage = React.lazy(() => import(`./event`))
    break
  case `post`:
    IndexPage = React.lazy(() => import(`./post`))
    break
  default:
    IndexPage = Default
}

export default IndexPage

export const Head: HeadFC = () => (
  <title>Georgian College Virtual Open House</title>
)
